import React from 'react'
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileVideo, faImages} from "@fortawesome/free-solid-svg-icons";

export default function FileUpload(props) {
    const imageFileTypes = ".ai, .bmp, .jpeg, .jpg, .gif, .pdf, .png, .psd, .svg, .tga, .tiff";
    const videoFileTypes = ".3gp, .avi, .ogv, .mkv, .mov, .mpeg, .mp4, .wmv";

    return (
        <div>
            <FileInput id='imageInput' className='input' type='file' accept={imageFileTypes} onChange={e => props.setImages(e.target.files)} multiple/>
            <FileInput id='videoInput' className='input' type='file' accept={videoFileTypes} onChange={e => props.setVideo(e.target.files[0])}/>
            <Media>
                <label style={{cursor: 'pointer'}} htmlFor='imageInput'>
                    <FileDiv>
                        <Fa icon={faImages}/><p>Add images</p>
                    </FileDiv>
                </label>
                {props.images && Array.from(props.images).map(image => <p key={image.name}>{image.name}</p>)}
                <label style={{cursor: 'pointer'}} htmlFor='videoInput'>
                    <FileDiv>
                        <Fa icon={faFileVideo}/><p>Add a video</p>
                    </FileDiv>
                </label>
            </Media>
            {props.video && <p>{props.video.name}</p>}
        </div>
    )
}

const FileInput = styled.input `
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
`

const FileDiv = styled.div `
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
`

const Media = styled.div`
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 0 2% 0;
`

const Fa = styled(FontAwesomeIcon)`
    width: 60px !important;
    height: 60px;

    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
`