import React from 'react';

export default function Redirect(props) {
    props.history.push('/Dashboard');

    return (
        <div>
            <h1>Redirecting...</h1>
        </div>
    )
}
