import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import PrivateRoute from "./utils/PrivateRoute.js";

import Header from "./components/Global/Header.js";
import Background from './components/Global/Background.js';
import LandingPage from "./components/Global/LandingPage.js";
import FormDash from './components/Global/Feedback/Forms/FormDash.js';
import Redirect from "./components/Global/Redirect.js";
import ViewJob from "../src/components/Global/ViewJob.js";

import Login from "./components/Account/Login.js";
import Profile from "./components/Account/Profile.js";
import Register from "./components/Account/Register.js";
import LoginRegister from "./components/Account/LoginRegister.js";

import Dashboard from "../src/components/Dashboard/Dashboard.js";

import { getCurrentUser, storeToken, setLoginFailed } from './redux-store/App/AppActions.js';
import { cognitoSetup, getIdTokenFromStorage } from './utils/cognitoFunctions.js';

ReactGA.initialize(process.env.REACT_APP_GTAG);

function App(props) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => { ReactGA.pageview(location.pathname + location.search); }, [location]);
  useEffect(() => { cognitoSetup(); }, []);

  useEffect(() => {
    if (!props.token && !props.loginFailed) {
      setLoading(true);
      getIdTokenFromStorage().then((token) => {
        props.storeToken(token);
        props.getCurrentUser();
      }).catch((err) => { console.log('App.js token useEffect error:', err); props.setLoginFailed('App.js token useEffect'); setLoading(false); });
    }
    else if (!props.currentUser && !props.loginFailed) { setLoading(true); props.getCurrentUser(); }
    else if (props.currentUser || props.loginFailed) { setLoading(false); }
    else { alert('something broke inside app.js token useEffect'); }
  }, [props.token, props.currentUser, loading])

  return (
    <div>
      <Header loggedIn={props.currentUser ? true : false} loading={loading}/>
      <Background/>
      <Route exact path='/' component={LandingPage} />
      {/* <Route path='/LoginRegister' render={props => <LoginRegister {...props} setLoading={setLoading} />} /> */}
      <Route path='/Login' render={props => <Login {...props} setLoading={setLoading} />} />
      <Route path='/Register' render={props => <Register {...props} setLoading={setLoading} />} />
      {/* <Route path='/Contact' component={Contact} /> */}
      <Route path='/Redirect/' component={Redirect} />
      <Route path='/ViewJob/:id' component={ViewJob} />
      <PrivateRoute path='/Bugs' component={FormDash} loading={loading} path={'/bugs'} currentUser={props.currentUser} />
      <PrivateRoute path='/Suggestions' component={FormDash} loading={loading} path={'/suggestions'} currentUser={props.currentUser} />
      {/* <PrivateRoute path='/Profile' component={Profile} loading={loading} path={'/profile'} /> */}
      <PrivateRoute path='/Dashboard' component={Dashboard} loading={loading} path={'/dashboard'} />
    </div>
  );
}

const mapStateToProps = state => {
  // console.log('mapstatetoprops: ', state);
  return {
    currentUser: state.AppReducer.currentUser,
    otherUser: state.AppReducer.otherUser,
    loading: state.AppReducer.loading,
    loginFailed: state.AppReducer.loginFailed,
    token: state.AppReducer.token
  }
}

export default connect(mapStateToProps, { getCurrentUser, storeToken, setLoginFailed })(App)