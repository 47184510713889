import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux';
import axiosWithAuth from '../../../utils/axiosWithAuth.js';
import JobCardQH from './JobCardQH.js';


function SuggestedJobs(props) {
    const [loading, setLoading] = useState(props.loading ? true : false);
    const [jobs, setJobs] = useState([]);
    const [jobStatusArray, setJobStatusArray] = useState([]);
    const [zip, setZip] = useState(props.currentUser.zipCode);
    const [newZip, setNewZip] = useState(props.currentUser.zipCode);

    useEffect(() => {
        if(props.currentUser && !props.jobs.length){
            if(props.currentUser.zipCode){
                setLoading(true);
                axiosWithAuth().post('/jobs/getByLocation', {zip: props.currentUser.zipCode, radius: 25})
                .then(response => {
                    // console.log('get jobs by location response: ', response);
                    setJobStatusArray(response.data.jobStatusArray);
                    if(response.data && response.data.jobs && response.data.jobs.length > 0){
                        setJobs(response.data.jobs);
                        props.setActiveJobID(response.data.jobs[0].id);
                    }
                    setLoading(false);
                })
                .catch(err => {
                    err.response ? console.log("suggestedJobs get jobs error:", err.response.data.message) : console.log("suggestedJobs get jobs error:", err)
                    setLoading(false);
                });
            }
        }
    },[props.currentUser]);

    useEffect(() => {if (props.loading){ setLoading(true);} }, [])
    useEffect(() => {
        if(props.jobs.length > 0 && props.jobStatusArray.length > 0){
            // console.log('ue firing', props.jobs, props.jobStatusArray);
            setJobStatusArray([...props.jobStatusArray]);
            setJobs([...props.jobs]);
            setLoading(false);
        }
    }, [props.jobs, props.jobStatusArray])

    const handleChange = e => { setNewZip(e.target.value); }

    const validateZip = () => {   
        if (!newZip){ alert('Zip code is required'); return false; }
        if (!/^\d{5}$/.test(newZip)){ alert('Invalid Zip Code. Format: xxxxx'); return false; }
        return true;
    }

    const updateZip = e => {
        e.preventDefault();
        if (validateZip()) {
            setLoading(true);
            axiosWithAuth().put('/users/user', {zip_code: newZip})
            .then(response => {
                // console.log('SuggestedJobs update user response:', res);
                setZip(newZip);
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log(`SuggestedJobs update user error: ${err.response.data.message}`);
                alert(`SuggestedJobs update user error: ${err.response.data.message}`);
            })
        }
    }

    return (
        <div className="card-container">
            {(!loading && jobs && jobStatusArray) && jobs.length > 0 && jobStatusArray.length > 0 && jobs.map((job, index) => {
                // console.log(job, index);
                // console.log(jobStatusArray[index], props.jobStatusArray[index])
                return (
                    <JobCardQH key={index} id={job.id} index={index} item={job} jobStatus={jobStatusArray[index]} setRecheckGoals={props.setRecheckGoals}
                    activeJobID={props.activeJobID} setActiveJobID={props.setActiveJobID} activeStatus={props.activeStatus} setActiveStatus={props.setActiveStatus}/>
                );
            })}
            {props.currentUser.zipCode 
                ? jobs && jobs.length ? null : loading ? <h1>Loading...</h1> : <h4>No Jobs found</h4>
                : <div style={{width: '100%', height: '30px'}}>
                    <input type="text" name="zip" value={newZip} placeholder="Update your zip code to pull local jobs automatically" onChange={handleChange} style={{width: '315px', marginRight: '10px'}}/>
                    <button onClick={updateZip}>SUBMIT</button>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
  // console.log('mapstatetoprops: ', state);
  return { currentUser: state.AppReducer.currentUser }
}

export default connect(mapStateToProps, { })(SuggestedJobs)