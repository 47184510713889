import React from 'react';
import { Route, useLocation } from 'react-router-dom';
import SubmitForm from './SubmitForm.js';
import ViewAllForms from './ViewAllForms.js';
import ViewForm from './ViewForm.js';


export default function FormDash(props) {
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    // console.log('dash props currentUser', props);

    return (
        <div className='formDash'>
            {path.includes('bugs') && <>
                <Route exact path='/Bugs/' component={ViewAllForms} />
                <Route path='/Bugs/Submit' component={SubmitForm} />
                {path !== '/bugs/submit' && <Route path='/Bugs/:id' render={routeProps => <ViewForm {...routeProps} currentUser={props.currentUser} />}/>}
            </>}
            {path.includes('suggestions') && <>
                <Route exact path='/Suggestions/' component={ViewAllForms} />
                <Route path='/Suggestions/Submit' component={SubmitForm} />
                {path !== '/suggestions/submit' && <Route path='/Suggestions/:id' render={routeProps => <ViewForm {...routeProps} currentUser={props.currentUser} />}/>}
            </>}
        </div>
    )
}