import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import axiosWithAuth from '../../utils/axiosWithAuth.js';
import { getBaseUrl } from '../../utils/AppUtils.js';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import indeedLogo from '../../images/IndeedIcon.png';

function ViewJob(props) {
    const [job, setJob] = useState('');
    const [loading, setLoading] = useState(true);
    let jobID = '';
    props.jobID ? jobID = props.jobID : props.match.params.id ? jobID = props.match.params.id : jobID = '';
    // console.log('ViewJob props:', props);

    useEffect(() => {
        if(jobID){
            if (jobID && props.currentUser){ setJobStatus(jobID, props.setActiveStatus, props.setRecheckGoals); } //Count view of the job for currentUser
            setLoading(true);
            axios.get(`${getBaseUrl()}/public/job/${jobID}`)
            .then(response => {
                // console.log('get job response: ', response);
                setJob(response.data);
                setLoading(false);
            })
            .catch(err => {
                // console.log('viewJob error:', err);
                console.log("ViewJob get job error:", err.response.data.message)
                setLoading(false);
            });
        }
        else{ setLoading(false); }
    },[jobID]);

    useEffect(() => {
        const container = document.querySelector('.viewJob-container');
        container.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
    }, [props.jobID])

    return (
        <div id={props.id} className="viewJob-container">
            {job 
                ? <div style={{width: '80%', margin: '0 auto'}}>
                    <div className='viewJob-Title'>
                        <div>
                            <h1>{job.title}</h1>
                            <h2>{job.companyName}</h2>
                            <h2>{job.city}, {job.state} {job.zipCode}</h2>
                        </div>
                        <div className="viewJob-Links">
                            {job.indeedUrl 
                                ? <a href={job.indeedUrl} title="Open on Indeed" target="_blank" rel="noopener noreferrer">
                                    <img src={indeedLogo} alt="Indeed logo icon"/>
                                  </a>
                                : null
                            }
                            {job.linkedinUrl 
                                ? <a href={job.linkedinUrl} title="Open on LinkedIn" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon style={{color: '#0077B5'}} icon={faLinkedin} />
                                  </a>
                                : null
                            }
                        </div>
                    </div>
                    <div>
                        <h3>Summary: </h3>
                        <p>{job.summary}</p>
                    </div>
                    <div>
                        <h3>Description: </h3>
                        <div dangerouslySetInnerHTML={{__html: job.description}} />
                    </div>
                </div>
                : loading ? <h2>Loading..</h2> : <h2>{jobID ? `Job with id ${props.match.params.id} not found` : 'Loading...'}</h2>
            }
        </div>
    )
}

const mapStateToProps = state => {
  // console.log('mapstatetoprops: ', state);
  return { currentUser: state.AppReducer.currentUser }
}

export default connect(mapStateToProps, { })(ViewJob)


const setJobStatus = (jobID, setActiveStatus, setRecheckGoals) => {
    // console.log('ViewJob setJobStatus firing')
    let d = new Date();
    const split = d.toString().split("GMT");
    const timezoneOffset = Number(split[1].slice(0, 3));
    
    axiosWithAuth().post('/jobs/status', {jobID, jobStatus: 'Viewed', viewedSite: 'Quackhire', timezoneOffset})
    .then(response => {
        // console.log('ViewJob setJobStatus response: ', response);
        setActiveStatus(response.data.jobStatus);
        if (setRecheckGoals){ setRecheckGoals(true); }
        axiosWithAuth().post('/history', {jobID, action: 'Viewed', jobSite: 'Quackhire'})
        .then(response => {
            // console.log('ViewJob setJobStatus updateHistory response: ', response);
        })
        .catch(err => { console.log("ViewJob setJobStatus updateHistory error:", err.response.data.message) });
    })
    .catch(err => { console.log("ViewJob setJobStatus error:", err.response ? err.response.data.message : err) });
}