import React, { useState, useEffect } from 'react';
import axiosWithAuth from '../../../utils/axiosWithAuth.js';
import { getBaseUrl } from '../../../utils/AppUtils.js';


export default function Toolbar(props) {
    const [frontendBaseUrl, setFrontendBaseUrl] = useState('');
    // console.log('toolbar props', props);

    useEffect(() => {
        if (getBaseUrl() === 'https://api.quackhire.com'){ setFrontendBaseUrl('https://quackhire.com'); }
        else { setFrontendBaseUrl('https://dev.quackhire.com'); }
    }, [])

    const handleButtonClick = (e) => {
        let status = ''
        if (e.target.name === 'save'){
            if (props.jobStatus.status) { status = props.jobStatus.status === 'Saved' ? 'Unsave' : 'Save'; }
            else { status = props.jobStatus === 'Saved' ? 'Unsave' : 'Save'; }
            setJobStatus(e, props.job.id, status, props.setJobStatus, '');
            props.setRecheckGoals(true);
        }
        else if (e.target.name === 'block'){
            if (props.jobStatus.status){ status = props.jobStatus.status === 'Blocked' ? 'Unblock' : 'Block'; }
            else{ status = props.jobStatus === 'Blocked' ? 'Unblock' : 'Block'; }
            setJobStatus(e, props.job.id, status, props.setJobStatus, '');
            props.setRecheckGoals(true);
        }
    }


    return (
        <div className='qh-toolbar'>
            <div className='qh-toolbar-left'>{getViewedText(props.job, props.jobStatus)}</div>
            <div className='qh-toolbar-right'>
                <button name='save' onClick={handleButtonClick}>{getSaveButtonText(props.jobStatus)}</button>
                <button name='block' onClick={handleButtonClick}>{getBlockButtonText(props.jobStatus)}</button>
                <a href={`${frontendBaseUrl}/ViewJob/${props.job.id}`} title="Open on Quackhire" target="_blank" rel="noopener noreferrer"><button>Open</button></a>
            </div>
        </div>
    )
}

const reverseDate = (dateString) => {
  let year = dateString.slice(2, 4);
  let month = dateString.slice(5, 7);
  let day = dateString.slice(8, 10);

  // remove 0 from first digit (ie 04/08/20 -> 4/8/20)
  if (day.slice(0, 1) === '0'){ day = day.slice(1, 2); }
  if (month.slice(0, 1) === '0'){ month = month.slice(1, 2); }

  return `${month}/${day}/${year}`;
}
const getViewedText = (job, jobStatus) => {
    let viewedText = '';

    if (jobStatus.status){
      if (jobStatus.status === 'Saved'){ viewedText = `Saved ${reverseDate(jobStatus.save_date)} on ${jobStatus.viewed_site}`; }
      else if (jobStatus.status === 'Blocked'){ viewedText = `Blocked ${reverseDate(jobStatus.block_date)} on ${jobStatus.viewed_site}`; }
      else if (jobStatus.status === 'Viewed'){ viewedText = `Viewed ${reverseDate(jobStatus.view_date)} on ${jobStatus.viewed_site}`; }
    }
    else{
      if (jobStatus === 'Saved'){ viewedText = `Saved ${reverseDate(job.save_date)} on ${job.viewed_site}`; }
      else if (jobStatus === 'Blocked'){ viewedText = `Blocked ${reverseDate(job.block_date)} on ${job.viewed_site}`; }
      else if (jobStatus === 'Viewed'){ viewedText = `Viewed ${reverseDate(job.view_date)} on ${job.viewed_site}`; }
      else{
        viewedText = `Viewed: Never`;
      }
    }
    // qhToolbarLeft.appendChild(viewed);
    return <p>{viewedText}</p>
}
const getSaveButtonText = (jobStatus) => {
    if(jobStatus.status){ return jobStatus.status === 'Saved' ? 'Unsave' : 'Save'; }
    else{ return jobStatus === 'Saved' ? 'Unsave' : 'Save'; }
}
const getBlockButtonText = (jobStatus) => {
    if(jobStatus.status){ return jobStatus.status === 'Blocked' ? 'Unblock' : 'Block'; }
    else{ return jobStatus === 'Blocked' ? 'Unblock' : 'Block'; }
}
const setJobStatus = (e, jobID, jobStatus, propsSetJobStatus, userNotes) => {
  if (e.isTrusted){
    // console.log('setJobStatus firing')
    let d = new Date();
    const split = d.toString().split("GMT");
    const timezoneOffset = Number(split[1].slice(0, 3));

    axiosWithAuth().post('/jobs/status', {jobID, jobStatus, userNotes, viewedSite: 'Quackhire', timezoneOffset})
    .then(response => {
        // console.log('setJobStatus response:', response.data);
        propsSetJobStatus(response.data.jobStatus);

        axiosWithAuth().post('/history', {jobID, action: jobStatus, jobSite: 'Quackhire'})
        .then(response => {
            // console.log('setJobStatus updateHistory response: ', response);
        })
        .catch(err => { console.log("setJobStatus updateHistory error:", err.response.data.message) });
    })
    .catch(err => { console.log("setJobStatus error:", err.response.data.message) });
  }
}