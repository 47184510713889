import React, { useState } from "react";
import { connect } from 'react-redux';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import { signIn, federatedSignIn, getIdTokenFromStorage } from '../../utils/cognitoFunctions.js';
import { getCurrentUser, storeToken } from '../../redux-store/App/AppActions.js';
import ForgotPassword from "./ForgotPassword";


function Login(props) {
    const [loading, setLoading] = useState(false);
    const [forgotPass, setForgotPass] = useState(false);
    const [user, setUser] = useState({ email: '', password: '' });
    // console.log('login props', props)

    const handleChange = e => { setUser({ ...user, [e.target.name]: e.target.value }) }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!user.email && !user.password) { return alert('Please enter your email and password') }
        else if (!user.email) { return alert('Please enter your email.') }
        else if (!user.password) { return alert('Please enter your password.') }
        else {
            setLoading(true);
            signIn(user.email, user.password).then(() => {
                getIdTokenFromStorage().then((token) => {
                    props.storeToken(token);
                    props.getCurrentUser();
                    props.setLoading(true);
                    props.history.push('/Dashboard');
                }).catch((err) => { console.log('login getToken error:', err); setLoading(false); props.setLoading(false); });
            }).catch(err => {
                console.log('Login signIn error:', err);
                setLoading(false);
                alert(`login signIn error: ${err.message}`);
            })
        }
    }

    const handleFederatedSignIn = (name) => {
        federatedSignIn(name)
            .then(() => {
                getIdTokenFromStorage().then((token) => {
                    props.storeToken(token);
                    props.getCurrentUser();
                    props.setLoading(true);
                    // props.history.push('/Dashboard');
                }).catch((err) => { console.log('login federatedSignIn error:', err); setLoading(false); props.setLoading(false); });
            }).catch((err) => {
                console.log('Login federatedSignIn error:', err);
                setLoading(false);
                alert(`Login federatedSignIn error: ${err.message}`);
            });
    }

    const toggleForgotPass = () => { setForgotPass(!forgotPass); }

    return (
        <div className="registerParent">
            <StyledLoader className="login-container" active={loading} spinner text='Loading...' styles={loaderStyles}>
                {!forgotPass
                    ? <>
                        <h3>LOG IN</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-inputs-login">
                                <input type="email" name="email" value={user.email} placeholder="Email" onChange={handleChange} />
                                <input type="password" name="password" value={user.password} placeholder="Password" onChange={handleChange} />
                            </div>
                            <div className="remember-and-submit-login">
                                <button onClick={handleSubmit}>LOG IN</button>
                            </div>
                        </form>
                        <a className="return-login" onClick={toggleForgotPass}>PASSWORD RESET</a>
                        <button className="google-login" onClick={() => { handleFederatedSignIn("Google") }}>Sign in with Google</button><br />
                    </>
                    : <>
                        <ForgotPassword setForgotPass={setForgotPass} setLoading={setLoading} />
                        <a className="return-login" onClick={toggleForgotPass}>RETURN TO LOGIN</a>
                    </>
                }
            </StyledLoader>
        </div>
    )
}

const mapStateToProps = state => {
    // console.log('mapstatetoprops: ', state);
    return { }
}

export default connect(mapStateToProps, { getCurrentUser, storeToken })(Login)

const StyledLoader = styled(LoadingOverlay)`width: 100%;`;

const loaderStyles = {
    overlay: (base) => ({
        ...base,
        borderRadius: '10px',
        background: '#ffffff',
        color: '#1351a8',
        fontWeight: 'bold'
    }),
    spinner: (base) => ({
        ...base,
        width: '10rem',
        '& svg circle': { stroke: '#1351a8' }
    })
}