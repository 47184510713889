import React, { useState } from 'react';
import axiosWithAuth from '../../../../utils/axiosWithAuth.js';
import { convertToFormData } from '../../../../utils/AppUtils.js';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import FileUpload from "./FileUpload.js";


export default function AddComment(props) {
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState({
        title: '',
        description: '',
        images: [],
        videoUrl: ''
    });
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');

    const handleChange = e => { setComment({ ...comment, [e.target.name]: e.target.value }); }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        axiosWithAuth().post(`/forms/report/${props.reportId}/comment`, convertToFormData({...comment, images, video}))
        .then(response =>{
            setLoading(false);
            props.setComments([...props.comments, response.data.comment]);
        }).catch(err => {
            setLoading(false);
            console.log('AddComment handleSubmit error:', err.response.data.message);
            alert(`AddComment handleSubmit error: ${err.response.data.message}`);
        })
    }

    return (
        <StyledLoader active={loading} spinner text='Loading...'>
            <div className="formContainer">
                <h3>ADD COMMENT</h3>
                <form onSubmit={handleSubmit}>
                    <div className="formInputs">
                        <input type="text" name="title" value={comment.title} placeholder="Enter Title" onChange={handleChange} />
                        <input type="text" name="videoUrl" value={comment.videoUrl} placeholder="Enter an external video url (Not required)" onChange={handleChange} />
                        <label>Description<br/>
                            <textarea name="description" value={comment.description} onChange={handleChange} placeholder="Enter your response"/>
                        </label>
                    </div>
                    <FileUpload images={images} setImages={setImages} video={video} setVideo={setVideo}/>
                    <div className="formSubmit">
                        <button onClick={handleSubmit}>SUBMIT</button>
                    </div>
                </form>
            </div>
        </StyledLoader>
    )
}

const StyledLoader = styled(LoadingOverlay)`
    width:100%;
    border-radius: 10px;
    z-index: 2;
`;