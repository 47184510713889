import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import axiosWithAuth from '../../../../utils/axiosWithAuth.js';
import AddComment from './AddComment.js';
import ImageModal from './ImageModal.js';
import EditForm from './EditForm.js';

export default function ViewForm(props) {
    const [report, setReport] = useState('');
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(true);
    const [activeImage, setActiveImage] = useState('');
    const [editingReport, setEditingReport] = useState('');
    const [editingCommentID, setEditingCommentID] = useState('');
    const location = useLocation();
    const path = location.pathname.toLowerCase();


    useEffect(() => {
        setLoading(true);
        axiosWithAuth().get(`/forms/report/${props.match.params.id}`)
        .then(response => {
            // console.log('get report response: ', response);
            setReport(response.data.report);
            setComments(response.data.comments);
            setLoading(false);
        })
        .catch(err => {
            console.log('viewreport error', err)
            setLoading(false);
            // console.log("ViewBugReport get report error:", err.response.data.message);
        });
    },[]);

    return (
        <div className='viewAllForms'>
            {report 
                ? <>
                    {reportCard(report, setReport, activeImage, setActiveImage, props.currentUser.id, editingReport, setEditingReport)}
                    {comments.length
                        ? comments.map((comment)=>{return commentCard(comment, setComments, activeImage, setActiveImage, props.currentUser.id, editingCommentID, setEditingCommentID);})
                        : null
                    }
                    <AddComment reportId={report.id} comments={comments} setComments={setComments}/>
                </>
                : loading ? <h2>Loading..</h2> : <h2>Report with id {props.match.params.id} not found</h2>
            }
        </div>
    )
}

const reportCard = (report, setReport, activeImage, setActiveImage, currentUserID, editingReport, setEditingReport) => {
    return (<>
        {editingReport
            ? <EditForm report={report} setReport={setReport} setEditingReport={setEditingReport}/>
            : <div className="formCard">
                <div style={{width: '80%', margin: '0 auto'}}>
                    <div>
                        <h3 style={{marginTop: '8px'}}>{report.title}</h3>
                        <p>Submitted by: {report.userName}</p>
                    </div>
                    <div>
                        <h3>Description: </h3>
                        <p>{report.description}</p>
                    </div>
                    {report.videoUrl ? handleVideoLink(report.videoUrl) : null }
                    {report.media 
                        ? report.media.length
                            ?   <div className='mediaDiv'>
                                {/* the two maps below is so that videos return separate from images */}
                                    {report.media.map((item)=>{
                                        if (item.isVideo){ return null; }
                                        else{ return showPicture(item, activeImage, setActiveImage); }
                                    })}
                                    {report.media.map((item)=>{
                                        if (item.isVideo){ return showVideo(item); }
                                        else{ return null; }
                                    })}
                                </div>
                            : null
                        : null
                    }
                    {(report.user_id === currentUserID && !editingReport) ? <button onClick={() => { setEditingReport(true); }}>Edit</button> : ''}
                </div>
            </div>
        }
    </>)
}

const commentCard = (comment, setComments, activeImage, setActiveImage, currentUserID, editingCommentID, setEditingCommentID) => {
    return (<>
        {editingCommentID === comment.id
            ? <EditForm comment={comment} setComments={setComments} setEditingCommentID={setEditingCommentID} />
            : <div className="formCard">
                <div style={{width: '80%', margin: '0 auto'}}>
                    <div>
                        <h3 style={{marginTop: '8px'}}>{comment.userName} replied:</h3>
                    </div>
                    <div>
                        <p>{comment.description}</p>
                    </div>
                    {comment.videoUrl ? handleVideoLink(comment.videoUrl) : null}
                    {comment.media 
                        ? comment.media.length 
                            ?   <div className='mediaDiv'>
                                {/* the two maps below is so that videos return separate from images */}
                                    {comment.media.map((item)=>{
                                        if (item.isVideo){ return null; }
                                        else{ return showPicture(item, activeImage, setActiveImage); }
                                    })}
                                    {comment.media.map((item)=>{
                                        if (item.isVideo){ return showVideo(item); }
                                        else{ return null; }
                                    })}
                                </div>
                            : null
                        : null
                    }
                    {(comment.user_id === currentUserID && !editingCommentID) ? <button onClick={() => { setEditingCommentID(comment.id); }}>Edit</button> : ''}
                </div>
            </div>
        }
    </>)
}

const showPicture = (image, activeImage, setActiveImage) => {
    if (image.url === activeImage){
        return (
        <ImageModal key={image.url} active={activeImage} setActiveImage={setActiveImage} modalToggle={'block'} src={image.url} 
        alt={`${image.filename}`} filename={image.filename} width={image.width} id={image.id} />  
    )}
    else {
        return (
        <ImageModal key={image.url} active={activeImage} setActiveImage={setActiveImage} modalToggle={'none'} src={image.url} 
        alt={`${image.filename}`} filename={image.filename} width={image.width} id={image.id}/>  
    )}
}

const showVideo = (video) => {
    return ( <iframe allowFullScreen={true} src={video.url} style={{width: '320px', height: '180px', marginBottom: '8px'}}/> )
}

const handleVideoLink = (url) => {
    if (url.includes('youtube.com/embed')){
        return showVideo({url});
    }
    if (url.includes('youtube.com/watch?v=')){
        let split = url.split('/watch?v=');
        return showVideo({url: `https://youtube.com/embed/${split[1]}`});
    }
    else if (url.includes('youtu.be/')){
        let split = url.split('youtu.be/');
        return showVideo({url: `https://youtube.com/embed/${split[1]}`});
    }
    else { return <p>External Video Link: {url}</p>; }
}