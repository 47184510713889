import React, { useState, useEffect } from 'react';
import Toolbar from './Toolbar.js';

export default function JobCardQH(props) {
    const [showToolbar, setShowToolbar] = useState(props.item.id === props.activeJobID ? true : false);
    const [jobStatus, setJobStatus] = useState(props.jobStatus 
        ? props.jobStatus
        : {
            applied_date: props.item.applied_date,
            block_date: props.item.block_date,
            companyID: props.item.companyID,
            job_id: props.item.job_id,
            save_date: props.item.save_date,
            status: props.item.status,
            view_date: props.item.view_date,
            viewed_site: props.item.viewed_site
        }
    );
    const [qhStyle, setQhStyle] = useState('');
    // console.log('jobcard props:', props);
    
    useEffect(() => {
        setJobStatus(props.jobStatus 
            ? props.jobStatus
            : {
                applied_date: props.item.applied_date,
                block_date: props.item.block_date,
                companyID: props.item.companyID,
                job_id: props.item.job_id,
                save_date: props.item.save_date,
                status: props.item.status,
                view_date: props.item.view_date,
                viewed_site: props.item.viewed_site
            }
        );
    }, [props.jobStatus])

    useEffect(() => {
        if (props.item.id === props.activeJobID){ 
            setShowToolbar(true);
            if(props.activeStatus){ setJobStatus(props.activeStatus); props.setActiveStatus('')}
        }
        else { setShowToolbar(false); }
    }, [props.activeJobID, props.activeStatus])

    useEffect(() => { setQhStyle(handleChromeStyling(jobStatus)); }, [jobStatus])

    const handleChromeStyling = (status) => {
        let qhClass = ''
        if(!props.history && status){
            if (status === 'Blocked' || status.status === 'Blocked'){ qhClass = 'qh-blocked'; }
            else if (status === 'Saved' || status.status === 'Saved'){ qhClass = 'qh-saved'; }
            else if (status === 'Viewed' || status.status === 'Viewed'){ qhClass = 'qh-viewed'; }
        }
        return qhClass;
    }
    
    const handleMouseEnter = () => { setShowToolbar(true); }
    const handleMouseLeave = () => { if (props.item.id !== props.activeJobID){setShowToolbar(false);} }

    return (
        // <a href={`${frontendBaseUrl}/ViewJob/${props.item.id}`} title="Open on Quackhire" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'black', width: '100%'}}>
            <div className={`${qhStyle} qh-JobCard`} onClick={()=>{props.setActiveJobID(props.item.id)}} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p style={{fontWeight: '800'}}>{props.item.title}</p>
                <p>{props.item.companyName}</p>
                <p>{props.item.city ? `${props.item.city}, ` : ''}{props.item.state ? `${props.item.state} ` : ''}{props.item.zipCode ? `${props.item.zipCode}` : ''}</p>
                <p style={{margin: '6px 0 6px 10px', maxWidth: '80%'}}>{props.item.summary}</p>
                {props.item.action && <p>Action: {props.item.action}</p>}
                {props.item.notes && props.item.notes.length && <p>Notes: {props.item.notes}</p>}
                {jobStatus && !props.history
                    ? showToolbar ? <Toolbar index={props.index} job={props.item} jobStatus={jobStatus} setJobStatus={setJobStatus} setRecheckGoals={props.setRecheckGoals} /> : null
                    : null
                }
            </div>
        // </a>
    )
}