import { SET_CURRENT_USER, LOGIN_FAILED, LOGOUT, STORE_TOKEN } from './AppActions.js';

const initialState = {
    currentUser: '',
    loading: true,
    loginFailed: false,
    imageFileTypes:".ai, .bmp, .jpeg, .jpg, .gif, .pdf, .png, .psd, .svg, .tga, .tiff,",
    videoFileTypes: ".3gp, .avi, .ogv, .mkv, .mov, .mpeg, .mp4, .wmv",
  };


export const AppReducer = (state = initialState, action) => {
    // console.log('AppReducer initialState: ', initialState);
    // console.log('AppReducer firing: ', action);
    switch(action.type) {
        case SET_CURRENT_USER:
            // console.log('SET_CURRENT_USER FIRING', state, action.payload);
            return {
                ...state,
                currentUser: action.payload,
                loginFailed: false,
            };
        case LOGIN_FAILED:
            // console.log('SET_CURRENT_USER FIRING', state);
            return { ...state, loginFailed: true, };
        case LOGOUT: 
            // console.log('LOGOUT FIRING', state);
            return{ ...state, currentUser: '', token: ''}
        case STORE_TOKEN: 
            // console.log('STORE_TOKEN FIRING', state, action.payload);
            return{ ...state, token: action.payload, }
        default: //console.log('REDUCER DEFAULT'); 
            return state;
  }
}
