import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import axiosWithAuth from '../../utils/axiosWithAuth.js';
import GoalGraph from '../Global/GoalGraph.js';

function DashboardNav(props) {
    const history = useHistory();
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const [searchValues, setSearchValues] = useState({
        zip: '',
        title: '',
        radius: 25
    });
    // console.log('dashNav path:', path)

    const validateZip = () => {   
        if (!searchValues.zip){ alert('Zip code is required'); return false; }
        if (!/^\d{5}$/.test(searchValues.zip)){ alert('Invalid Zip Code. Format: xxxxx'); return false; }
        return true;
    }
    const handleChange = e => { setSearchValues({ ...searchValues, [e.target.name]: e.target.value }); }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateZip()) {
            props.setLoading(true);
            axiosWithAuth().post('/jobs/search', searchValues)
            .then(response => {
                // console.log('DashboardNav search jobs response:', response);
                if(response.data && response.data.jobs && response.data.jobs.length > 0){
                    props.setJobs(response.data.jobs);
                    props.setJobStatusArray(response.data.jobStatusArray);
                    props.setActiveJobID(response.data.jobs[0].id);
                    history.push('/Dashboard/');
                }
                else{ alert('No jobs found with those search parameters') }
                props.setLoading(false);
            }).catch(err => {
                props.setLoading(false);
                console.log('DashboardNav search jobs error', err);
                // console.log(`DashboardNav search jobs error: ${err.response.data.message}`);
                // alert(`DashboardNav search jobs error: ${err.response.data.message}`);
            })
        }
    }

    return (
        <>
            <div className="dashboardNavParent">
                <nav className="dashboardNav">
                    <Link to='/Dashboard/'><h3 style={(path === '/dashboard' || path === '/dashboard/') ? {color: '#F06A67'} : {}}>Suggested</h3></Link>
                    <Link to='/Dashboard/History'><h3 style={path.includes('/dashboard/history') ? {color: '#F06A67'} : {}}>History</h3></Link>
                    <Link to='/Dashboard/Jobs/Viewed'><h3 style={path.includes('/dashboard/jobs/viewed') ? {color: '#F06A67'} : {}}>Viewed</h3></Link>
                    <Link to='/Dashboard/Jobs/Saved'><h3 style={path.includes('/dashboard/jobs/saved') ? {color: '#F06A67'} : {}}>Saved</h3></Link>
                    <Link to='/Dashboard/Jobs/Blocked'><h3 style={path.includes('/dashboard/jobs/blocked') ? {color: '#F06A67'} : {}}>Blocked</h3></Link>
                </nav>
                <form className="dashSearchBar">
                    <input type="text" name="title" value={searchValues.title} placeholder="Title" onChange={handleChange} style={{width: '50%'}}/>
                    <input type="text" name="zip" value={searchValues.zip} placeholder="Zip code" onChange={handleChange} style={{width: '60px'}}/>
                    <select name="radius" value={searchValues.radius} onChange={handleChange}>
                        <option value={10}>10 miles</option>
                        <option value={25}>25 miles</option>
                        <option value={50}>50 miles</option>
                        <option value={100}>100 miles</option>
                    </select>
                    <button onSubmit={handleSubmit} onClick={handleSubmit}>Search</button>
                </form><br/>
            </div>
            <GoalGraph recheckGoals={props.recheckGoals} setRecheckGoals={props.setRecheckGoals}/>
        </>
    )
}

export default DashboardNav;