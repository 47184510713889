import axios from 'axios';
import store from '../index.js'
import { getBaseUrl } from './AppUtils';

export default function axiosWithAuth() {
    const state = store.getState();
    // console.log('awa state:', state);
    // console.log('awa token:', state.AppReducer.token);
    
    // const token = useSelector(state => state.AppReducer.token);

    return axios.create({
        baseURL: getBaseUrl(),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${state.AppReducer.token}`,
        },
    });
};