const configMaster = {
    "clientId": "39d98uhunvuom20pcirm40cfmk",
    "userPoolUri": "quackhire.auth.us-west-2.amazoncognito.com",
    "userPool": "us-west-2_bjrOXvDet",
    "region": "us-west-2",
    "callbackUri": "https://www.quackhire.com/Redirect/",
    "signoutUri": "https://www.quackhire.com/",
    "tokenScopes": [
        "openid",
        "email",
        "profile",
        "aws.cognito.signin.user.admin"
    ]
}

const configDev = {
    "clientId": "7n16tqj8le1o2vl4onglghml00",
    "userPoolUri": "quackhire-dev.auth.us-west-2.amazoncognito.com",
    "userPool": "us-west-2_Bd1uRJdu3",
    "region": "us-west-2",
    "callbackUri": "https://dev.quackhire.com/Redirect/",
    "signoutUri": "https://dev.quackhire.com/",
    "tokenScopes": [
        "openid",
        "email",
        "profile",
        "aws.cognito.signin.user.admin"
    ]
}

const configLocal = {
    "clientId": "7n16tqj8le1o2vl4onglghml00",
    "userPoolUri": "quackhire-dev.auth.us-west-2.amazoncognito.com",
    "userPool": "us-west-2_Bd1uRJdu3",
    "region": "us-west-2",
    "callbackUri": "http://localhost:3000/Redirect/",
    "signoutUri": "http://localhost:3000/",
    "tokenScopes": [
        "openid",
        "email",
        "profile",
        "aws.cognito.signin.user.admin"
    ]
}

export default function getConfig() {
    const url = window.location.href.toString();
    if (url.includes('https://quackhire.com') || url.includes('https://www.quackhire.com') || url.includes('www.quackhire.com')){
        return configMaster;
    }
    else if (url.includes('localhost')){ return configLocal; }
    else{ return configDev; }
}