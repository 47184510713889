import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import axiosWithAuth from '../../utils/axiosWithAuth.js';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";


function GoalGraph(props) {
    const [loading, setLoading] = useState(true);
    const [month, setMonth] = useState(false);
    const [year, setYear] = useState(false);
    const [dateArray, setDateArray] = useState([]);
    const [graphOpen, setGraphOpen] = useState(false);
    const date = new Date(year, month, 1);  // yyyy-mm-dd
    const monthName = date.toLocaleString('default', { month: 'long' });
    // console.log('GoalGraph props:', props);

    const getTitleText = (input, i) => {
        let title = ''
        let date = new Date();
        if (input === 'No activity'){
            if (date.getDate() < i + 1){ title = '' }
            else{ title = 'No Activity'; }
        }
        else{
            title = `Viewed: ${input.currentViewed}/${input.viewedGoal} Saved: ${input.currentSaved}/${input.savedGoal} Applied: ${input.currentApplied}/${input.appliedGoal}`;
        }
        return title;
    }

    const getSquareColor = (input, i, d) => {
        let date = new Date();
        let greenPercentage = 100;
        let border = '1px solid black';
        let borderBottomLeftRadius = '';
        let borderBottomRightRadius = '';
        if(dateArray.length-1 - i <= 7 && d === 0){ borderBottomLeftRadius = '8px'; }
        else if (i === dateArray.length-1 && d === 6){ borderBottomRightRadius = '8px'; }

        if (date.getDate() === i+1){ border = `1px solid orange`}
        if (input !== 'No activity'){
            greenPercentage -= (25 * input.currentViewed/input.viewedGoal);
            greenPercentage -= (25 * input.currentSaved/input.savedGoal);
            greenPercentage -= (15 * input.currentApplied/input.appliedGoal);
        }
        else{
            if (date.getDate() > i + 1){ return {background: `silver`, borderBottomLeftRadius, borderBottomRightRadius}; }
            else { return {background: 'white', border, borderBottomLeftRadius, borderBottomRightRadius}; }
        }
        return {background: `hsla(121, 72%, ${greenPercentage}%, 1)`, border, borderBottomLeftRadius, borderBottomRightRadius};
    }

    const checkMonth = (input) => {
        if (props.currentUser && props.currentUser.firstGoalMonthYear && month && year){
            let firstMonth = props.currentUser.firstGoalMonthYear.slice(0,2);
            let firstYear = props.currentUser.firstGoalMonthYear.slice(3,7);
            
            if(input === 'prev'){
                let prevMonth = month === 0 ? 11 : month-1;
                let localYear = month === 0 ? year-1 : year;
                // console.log(`month: ${month}, year: ${year}, firstMonth: ${firstMonth}, firstYear: ${firstYear}, prevMonth: ${prevMonth}, localYear: ${localYear}, FGMY: ${props.currentUser.firstGoalMonthYear}`)
                if (localYear < firstYear || (localYear <= firstYear && prevMonth <= firstMonth)){
                    return false;
                }
                else{ return true; }
            }
            else if(input === 'next'){
                let date = new Date();
                let currentMonth = date.getMonth();
                let currentYear = date.getFullYear();
                let nextMonth = month === 11 ? 0 : month+1;
                let localYear = month === 11 ? year+1 : year;
                // console.log(`month: ${month}, year: ${year}, currentMonth: ${currentMonth}, currentYear: ${currentYear}, nextMonth: ${nextMonth}, localYear: ${localYear}`)
                if (localYear > currentYear || (localYear >= currentYear && nextMonth >= currentMonth)){
                    return false;
                }
                else{ return true; }
            }
        }
        else{ return false; }
    }

    const updateMonth = (input) => {
        // console.log('updateMonth firing');
        if(input === 'prev'){
            if (props.currentUser && props.currentUser.firstGoalMonthYear){
                if (month === 0){
                    setMonth(11);
                    setYear(year-1);
                }
                else { setMonth(month-1); }
            }
        }
        else if(input === 'next'){
            if (month === 11){
                setMonth(0);
                setYear(year+1);
            }
            else { setMonth(month+1); }
        }
    }

    const toggle = () => { setGraphOpen(!graphOpen); }

    useEffect(() => {
        let d = new Date();
        setMonth(Number(d.getMonth().length === 1 ? `0${d.getMonth()}` : d.getMonth()));
        setYear(Number(d.getFullYear()));
    }, [])

    useEffect(() => {
        if (month !== false && year !== false){
            setLoading(true);
            axiosWithAuth().post('/users/user/getMonthlyGoals', {month, year})
            .then(response => {
                // console.log('getMonthlyGoals response: ', response);
                if(response && response.data){
                    setDateArray(response.data.dateArray);
                }
                setLoading(false);
                props.setRecheckGoals(false);
            })
            .catch(err => {
                err.response ? console.log("getMonthlyGoals error:", err.response.data.message) : console.log("getMonthlyGoals error:", err)
                setLoading(false);
            });
        }
    },[month, year, props.recheckGoals]);

    return (
        <div className='goalGraphParent' style={graphOpen ? {} : {visibility: 'hidden'}}>
            {month && year && dateArray.length > 0 &&
                <div className='goalGraphContainer' style={graphOpen ? {} : {marginLeft: '-100%'}}>
                    <StyledLoader active={loading} spinner text='Loading...' styles={loaderStyles}>
                    <div className='goalGraphRow'>
                        <p style={checkMonth('prev') ? {cursor:'pointer'}: {}} title='Previous Month' onClick={()=>{if (checkMonth('prev')){updateMonth('prev'); }}}>{checkMonth('prev') ? '<' : ''}</p>
                        <p>{monthName}</p>
                        <p style={checkMonth('next') ? {cursor:'pointer'}: {}} title='Next Month' onClick={()=>{if (checkMonth('next')){updateMonth('next');}}}>{checkMonth('next') ? '<' : ''}</p>
                    </div>
                    <div className='goalGraphRow'><p>Su</p><p>Mo</p><p>Tu</p><p>We</p><p>Th</p><p>Fr</p><p>Sa</p></div>
                    {(()=>{
                        let rows = [];
                        let row = [];
                        for(let i = 0; i < dateArray.length; i++){
                            // console.log('i, dateArray', i, dateArray.length, dateArray)
                            for (let d = 0; d < 7; d++){ // return rows of 7
                                if (d === 0){ row = []; }

                                if (i === 0){
                                    let day = date.getDay();
                                    //return null squares before first day of month
                                    while(d < day){ row.push(<div key={`${i}-${d}`} className='goalGraphSquareNull'></div>); d++;}
                                    //return normal date square div based off of dateArray[i]
                                    row.push(<div key={`${i}-${d}`} className='goalGraphSquare' title={getTitleText(dateArray[i], i)} style={getSquareColor(dateArray[i], i, d)}>{i+1}</div>);
                                }
                                else if (i >= dateArray.length){
                                    let lastDate = new Date(year, month, i);
                                    let day = lastDate.getDay();
                                    //return null squares after last day of month
                                    if(d > day){ row.push(<div key={`${i}-${d}`} className='goalGraphSquareNull' style={d === 6 ? {borderBottomRightRadius: '8px'} : {}}></div>); }
                                    //return normal date square div based off of dateArray[i]
                                    else{ row.push(<div key={`${i}-${d}`} className='goalGraphSquare' title={getTitleText(dateArray[i], i)} style={getSquareColor(dateArray[i], i, d)}>{i+1}</div>); }
                                }
                                //return normal date square div based off of dateArray[i]
                                else{ row.push(<div key={`${i}-${d}`} className='goalGraphSquare' title={getTitleText(dateArray[i], i)} style={getSquareColor(dateArray[i], i, d)}>{i+1}</div>); }

                                i++; //increase day
                                if (d === 6){ rows.push(<div key={i/4} className='goalGraphRow'>{row.map((item)=>{return item})}</div>); i--; }
                            }
                            if(i >= dateArray.length -1){return <>{rows.map((item)=>{return item})}</>}
                        }
                    })()}
                    </StyledLoader>
                </div>
            }
            <div className='goalDiv' onClick={toggle} style={graphOpen ? {} : {visibility: 'visible'}}>
                <p>G<br/>O<br/>A<br/>L<br/>S</p>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    // console.log('mapstatetoprops: ', state);
    return { currentUser: state.AppReducer.currentUser }
  }
  
  export default connect(mapStateToProps, { })(GoalGraph)

  const StyledLoader = styled(LoadingOverlay)`
      width: 100%;
      border-radius: 8px;
      z-index: 20;
  `;
  
  const loaderStyles = {
    overlay: (base) => ({
        ...base,
        borderRadius: '8px',
        background: '#ffffff',
        color: '#1351a8',
        fontWeight: 'bold'
    }),
    spinner: (base) => ({
        ...base,
        height: '55px',
        '& svg circle': { stroke: '#1351a8' }
    })
  }