import React, { useState } from "react";
import { forgotPassword, forgotPasswordSubmit } from '../../utils/cognitoFunctions.js'
import { isValidPassword } from '../../utils/AppUtils.js'

export default function ForgotPassword(props) {
    const [user, setUser] = useState({ email: '', newPassword: '', confirmNewPassword: '' });

    const [emailSent, setEmailSent] = useState('');
    const [code, setCode] = useState('');
    
    const handleChange = e => { setUser({ ...user, [e.target.name]: e.target.value }) }
    const handleCodeChange = e => { setCode(e.target.value) }

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if(!user.email) { return alert('Please enter your email.') }
        else {
            props.setLoading(true);
            forgotPassword(user.email).then(()=>{
                setEmailSent(true);
                props.setLoading(false);
            }).catch(err => {
                console.log('ForgotPassword handleSubmit error:', err);
                props.setLoading(false);
                alert(`ForgotPassword handleSubmit error: ${err.message}`);
            })
        }
    }

    const handleCodeSubmit = (e) => {
        e.preventDefault();
        if(!code) { return alert('Please enter the verification code') }
        else if (isValidPassword(user.newPassword, user.confirmNewPassword)){
            props.setLoading(true);
            forgotPasswordSubmit(user.email, code, user.newPassword).then(()=>{
                props.setLoading(false);
                props.setForgotPass(false);
            }).catch(err => {
                console.log('ForgotPassword handleCodeSubmit error:', err);
                props.setLoading(false);
                alert(`ForgotPassword handleCodeSubmit error: ${err.message}`);
            })
        }
    }

    return (
        <>
            <h3>PASSWORD RESET</h3>
            { !emailSent
            ?   <form onSubmit={handleSubmit}>
                    <div className="form-inputs-login">
                        <input type="email" name="email" value={user.email} placeholder= "Email" onChange={handleChange} />
                    </div>
                    <div className="remember-and-submit-login">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </form>
            :   <div>
                    <h2>We sent you an email- please enter the verification code and a new password.</h2>
                    <form onSubmit={handleCodeSubmit}>
                        <div className="form-inputs-login">
                            <input type="text" name="code" value={code} placeholder= "Verification Code" onChange={handleCodeChange} />
                            <input type="password" name="newPassword" value={user.newPassword} placeholder="New Password" onChange={handleChange} />
                            <input type="password" name="confirmNewPassword" value={user.confirmNewPassword} placeholder="Confirm New password" onChange={handleChange} />
                        </div>
                        <button onClick={handleCodeSubmit}>Submit</button>
                    </form>
                </div>
            }
        </>
    )
}
