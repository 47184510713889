import React, {useState, useEffect} from 'react';
import axiosWithAuth from '../../../utils/axiosWithAuth.js';
import JobCard from './JobCardQH.js';

export default function History(props) {
    const [userHistory, setUserHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axiosWithAuth().get('history')
        .then(response => {
            // console.log('get user jobHistory response:', response);
            if (response.data && response.data.userJobHistory && response.data.userJobHistory.length){
                setUserHistory(response.data.userJobHistory);
                props.setActiveJobID(response.data.userJobHistory[0].id);
            }
            setLoading(false);
        })
        .catch(err => {
            // console.log('user Jobs err:', err);
            console.log("get user jobHistory error:", err.response.data.message)
            setLoading(false);
        });
    }, [])

    return (
        <div className="card-container">
            {userHistory && userHistory.length > 0 && userHistory.map((item, index) => {
                return <JobCard key={index} item={item} index={index} history={true} setRecheckGoals={props.setRecheckGoals}
                activeJobID={props.activeJobID} setActiveJobID={props.setActiveJobID}/>
            })}
            {userHistory && !userHistory.length && loading ? <h1>Loading...</h1> : <p>No Jobs found</p>}
        </div>
    )
}