import React, { useState, useEffect } from "react"
import QuackImage from "../../images/quack-image-transparent.png"
import trackStatusGif from "../../images/TrackStatus.gif"
import goalGraph from "../../images/GoalGraph.gif"
import changeStyling from "../../images/ChangeStyling.gif"
import quackHire from "../../images/QuackHire.gif"



const LandingPage = () => {
    const [y, setY] = useState('');
    const [imageLoading, setImageLoading] = useState(true);
    const [currentGif, setCurrentGif] = useState(0);
    const [gifArray] = useState([
        { source: trackStatusGif, alt: 'Track Status feature gif example', text: 'Track jobs with a single click', border: 'none', maxWidth: '800px', margin: '0 10% 0 0'},
        { source: goalGraph, alt: 'Goal Graph feature gif example', text: 'Set and track daily goals', border: 'none', maxWidth: '800px', margin: '0 10% 0 0'},
        { source: changeStyling, alt: 'Change Styling feature gif example', text: 'Modify styling by status', border: '1px solid #1351a8', width: 'calc(~"(100vh - 150px")*.767)', margin: '-2% 5% 0 0'},
        { source: quackHire, alt: 'Quackhire dashboard gif example', text: 'Search for and manage jobs on Quackhire.com', border: '1px solid #1351a8', margin: '-2% 5% 0 0'},
    ]);
    
    useEffect(() => {
        const header = document.querySelector('header');
        const element = document.querySelector('.landing-container');
        setY(element.getBoundingClientRect().bottom-header.clientHeight);
    }, [])

    useEffect(() => {
        if(!imageLoading){
            if(currentGif === 0){ setTimeout(function(){ setCurrentGif(1) }, 11500); }
            if(currentGif === 1){ setTimeout(function(){ setCurrentGif(2) }, 13516); }
            if(currentGif === 2){ setTimeout(function(){ setCurrentGif(3) }, 13576); }
            if(currentGif === 3){ setTimeout(function(){ setCurrentGif(0) }, 13509); }
        }
    }, [currentGif, imageLoading])

    return (
        <>
            <div className = "landing-container">
                <div className = "text-container">
                    <div className = "text-content">
                        <h1>Click. Save. Apply.</h1>
                        <h2>Get Hired faster.</h2>
                        {/* <h2>CurrentGif: {currentGif}</h2> */}
                    </div>
                    <p>Keeping track of jobs you find online is hard. Bookmarking, tracking them on a spreadsheet, or just keeping them open in separate tabs gets messy fast. With the Quackhire Chrome extension you can save jobs from your favorite job sites with a single click, or block them and never see them again. We track which jobs you have viewed so you don’t spend hours looking at the same listings over and over again.<br/><br/>No more wasted time, no more missed opportunities.</p>
                    <a href="https://chrome.google.com/webstore/detail/quackhire/jmpkknkklhccclhobkgdbedobhenljlk" target="_blank" rel="noopener noreferrer"><button>Install Now</button></a>
                </div>
                <div className ="img-container" style={{margin: gifArray[currentGif].margin, maxWidth: gifArray[currentGif].maxWidth}}>
                    {/* <img src={QuackImage} alt="Quackhire duck logo"/> */}
                    <h2>{gifArray[currentGif].text}</h2>
                    <img src={gifArray[currentGif].source} alt={gifArray[currentGif].alt} style={{border: gifArray[currentGif].border}} onLoad={()=>{setImageLoading(false)}}/>
                </div>
            </div>
            {/* <div className="landingLearnMore">
                <div className="learnMoreFlex">


                </div> */}
              {/*  <div className="learnMoreFlex">
                    <div className="learnMoreText">
                        <h1>Quackhire is:</h1>
                        <p>A job hunting platform</p>
                        <p>A Chrome Extension that adds functionality to sites you already use</p>
                        <p>An easy way to track and manage job listings from all major platforms</p>
                        <p>Free to use!</p>
                    </div>
                    <div className='learnMoreImages'>
                        <img loading="lazy" src={extension} alt="Open Extension example gif"/>
                        <p>View joblists and update settings inside the extension</p>
                    </div>
                </div>
                <div className="learnMoreFlex">
                    <div className="learnMoreText">
                        <h1>Why should you use it?</h1>
                        <p>Are you stuck in a job you hate because finding a new job is worse?</p>
                        <p>Do you go through pages and pages of listings and can't remember what you've already seen?</p>
                        <p>Do you feel like you spend all day looking and not making any progress?</p>
                        <p>Do you feel like your time is being wasted and searching could be more efficient?</p>
                        <p>It can!</p>
                    </div>
                    <div className='learnMoreImages'>
                        <img loading="lazy" src={statusAndStyling} alt="Status and Styling example gif"/>
                        <p>Track job statuses and modify styling based off your preference</p>
                    </div>
                </div>
                <div className="learnMoreFlex">
                    <div className="learnMoreText">
                        <h1>How it works</h1>
                        <p><a href="https://chrome.google.com/webstore/detail/quackhire/jmpkknkklhccclhobkgdbedobhenljlk" target="_blank" rel="noopener noreferrer">Install the extension</a> and navigate to a supported job site.</p>
                        <p>Click the duck to open the extension and sign in.</p>
                        <p>Inside the extension you can view you history, Viewed/Saved/Blocked job lists, and adjust your settings.</p>
                        <p>Mouse over jobs to see the toolbar.</p>
                        <p>Clicking a job marks it as viewed, and hitting the save or block buttons marks it as saved or blocked.</p>
                    </div>
                    <div className='learnMoreImages'>
                        <img loading="lazy" src={openGif} alt="Open Extension Gif"/>
                        <p>Manage your jobs and find new ones at Quackhire.com  </p>
                    </div>
                </div>
                <div className="learnMoreFlex">
                    <div className="learnMoreText" style={{width: '96%'}}>
                        <h1>Free? How are you making money?</h1>
                        <p>We're not! We're still working on essential features/polish and don't believe in charging for an incomplete product.</p>
                        <p>We also don't believe in selling your data- We're operating out of pocket and on promotional credits as we grow.</p>
                        <p>Whatever monetization strategy we decide on in the future will be communicated clearly and with as much notice as possible.</p>
                    </div>
                </div>
                <div className="learnMoreFlex">
                    <div className="learnMoreText" style={{width: '96%'}}>
                        <h1>Is the Chrome Extension safe to use?</h1>
                        <p>The extension can only run on authorized websites (Currently Indeed and LinkedIn)</p>
                        <p>We ask for access to Chrome Storage to be able to save your login credentials</p>
                        <p>Login and authorization is implemented following best practices through Amazon Cognito</p>
                        <p>Our database is encrypted and hosted on a secure cloud platform</p>
                        <p>We will never sell your data, and don't have any partners to 'share' it with</p>
                    </div>
                </div>
                <div className="learnMoreFlex">
                    <div className="learnMoreText" style={{width: '96%'}}>
                        <h1>Coming soon/current priorities</h1>
                        <p>Onboard first hundred users and fix any bugs that are discovered</p>
                        <p>Styling and UX overhaul</p>
                        <p>Duplicate job tracking across sites- block a job on Indeed and you wont see it on Linkedin.</p>
                        <p>Job search and recommendation improvements on Quackhire.com</p>
                    </div>
                </div> */}
            {/* </div> */}
        </>
    )
}

export default LandingPage


// Free? How are you making money?
//  -We're not! We're still working on essential features/polish and don't believe in charging for an incomplete product.
//  -We also don't believe in selling your data- We're operating out of pocket and on promotional credits as we grow.
//  -Whatever monetization strategy we decide on in the future will be communicated clearly and with as much notice as possible.

// e notes + additions:
// Why do I want this? Who am I? What does it do?
//   - considering new opportunities but adverse to mass CE corp entities?(Millenials)
//   - Organized, bust, important
//   - Spend all day looking, jobs blend into each other
//   - feeling of no progress, inefficient
//   - Confidence in listing org (that you know this is the first time you saw listing?)
//   - No wasted time

// How does it work?
//  Step 1 -> Go to site (Indeed/Linkedin), now you see icon open
//      gif/screenshot show toolbar/ext
//  Step 2 -> Hover over Job
//      gif/ss show save block notes buttons
//  Step 3 -> Click job/button, counts viewed and changes status
//      gif/ss
// introduce goals idea
// alt: Step 1, install ext and go to supported site
    //step 2: Click it, track it/Love it save it/hate it block it
            //three gifs in a flex row
            // extra tags below? hate it: Never waste your time again on a job you are not interested in/
    //step three: Track your goals
    //step 4: set your prefs
    // step 5: show up daily and get hired

// Why Quackhire? What makes us unique?
// Currently free! We want to provide a massive amount of value before asking for anything in return. We're eating server costs and running off credits while we grow- not selling your data.
// Other solutions only work on their site, or require you to copy paste data into them. Might as well stick to your spreadsheet.
    //With QH, everything is done automatically with one click, and we will rapidly grow to all major and minor job boards once the core product is complete.
    //Never lose another opportunity! (get started today button?)
// Blocking a job or company on linkedin blocks them everywhere! quit wasting your time

// Is it safe? 'it's a big deal to allow apps access to permissions- here's why we need the ones we ask for.'
    // EXT only runs on supported job platforms.
    // Access to Chrome Storage to save your login tokens
    // Database is encrypted and hosted on a secure cloud platform
    // Login and oAuth is implemented following best practices through Amazon Cognito
    // We will never sell your data, and do not have any partners to 'share' it with.

// What's coming next/priorities list?
// Minor tweaks/fixes/oversites that were not worth delaying the initial release
// Iterating based off of initial user feedback. What features do users wish for or love?
// UI and UX overhaul
// Bug Fixes/stability for issues discovered by testers
// Implementing duplicate job tracking across sites
// Improvements to searching for jobs and recommendations on Quackhire.com
    //We'll know what you are looking for and what you've already seen, and pull data from all major platforms to show you the best results instantly
// Onboarding new platforms that user's already use to hunt for jobs, like Monster/Zip Recruiter/RemoteOK/etc

// old notes 8/21"
// Introducing Quackhire: Job hunting made easy.
    //Quackhire is a job hunting platform and chrome extension that adds functionality to sites you already use.
    // It allows you to track, save, and block jobs with a click of a button. No more spreadsheets, no more bookmarks.
