import React, { useState } from "react";
import { connect } from 'react-redux';
// import BackgroundImage from "../../images/mountain.jpg";
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import Background from "../Global/Background.js";
import { signIn, signUp, federatedSignIn, getIdTokenFromStorage } from '../../utils/cognitoFunctions.js';
import { isValidPassword, validateInputs } from '../../utils/AppUtils.js';
import { getCurrentUser, storeToken } from '../../redux-store/App/AppActions.js';
import ForgotPassword from "./ForgotPassword";
// import Background from "../Global/Background.js";


export default function LoginRegister() {
    const [loading, setLoading] = useState(true);

    const slideRight = () => {
        const container = document.querySelector("lr-container");
        const signUpButton = document.querySelector("signUp");
        const signInButton = document.querySelector("signIn");

        // signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'));
        // signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'));

        signUpButton.classList.add("right-panel-active");
        signInButton.classList.remove("right-panel-active");
    }
    const slideLeft = () => {
        const card = document.querySelector(".lr-signup-form-container");
        card.classList.add("lr-slideleft");
    }
    // imported code from login.js
    // imported code from registration.js

    return (
        <div className="lr-parent-container">
            {/* considering we ahve two background for registration adn login, is it possible we can do a Math.random logic stuff to have a random chance of having either background on the login-register page? */}
            <Background setLoading={setLoading} />
            <div className="lr-container">
                <div className="lr-form-container sign-up-container">
                    <form action="#">
                        <h1>Create Account</h1>
                        <div className="lr-social-container">
                            {/* <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                        <span>or use your email for registration</span>
                        <input type="text" placeholder="Name" />
                        <input type="email" placeholder="Email" />
                        <input type="password" placeholder="Password" />
                        <button>Sign Up</button>
                    </form>
                </div>
                <div className="lr-form-container sign-in-container">
                    <form action="#">
                        <h1>Sign in</h1>
                        <div className="lr-social-container">
                            {/* <a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a> */}
                        </div>
                        <span>or use your account</span>
                        <input type="email" placeholder="Email" />
                        <input type="password" placeholder="Password" />
                        <a href="#">Forgot your password?</a>
                        <button>Sign In</button>
                    </form>
                </div>
                <div className="lr-overlay-container">
                    <div className="lr-overlay">
                        <div className="lr-overlay-panel overlay-left">
                            <h1>Welcome Back!</h1>
                            <p>To keep connected with us please login with your personal info</p>
                            <button className="ghost" id="signIn">Sign In</button>
                        </div>
                        <div className="lr-overlay-panel overlay-right">
                            <h1>Hello, Friend!</h1>
                            <p>Enter your personal details and start journey with us</p>
                            <button className="ghost" id="signUp">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const StyledLoader = styled(LoadingOverlay)`
    width:100%;
    z-index: 2;
    display: flex;
    flex-direction: column;
`;

const loaderStyles = {
    overlay: (base) => ({
        ...base,
        background: '#ffffff',
        color: '#1351a8',
        fontWeight: 'bold'
    }),
    spinner: (base) => ({
        ...base,
        width: '10rem',
        '& svg circle': {
            stroke: '#1351a8'
        }
    })
}