import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import axiosWithAuth from '../../../utils/axiosWithAuth.js';
import JobCard from './JobCardQH.js';
import { forceLogOut } from '../../../utils/AppUtils.js';

export default function JobComponent(props) {
    const [userJobs, setUserJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    let history = useHistory();
    // console.log('jobComponent props:', props);

    useEffect(() => {
        setLoading(true);
        axiosWithAuth().get(`/users/user/jobs/${props.currentComponent}`)
        .then(response => {
            // console.log('get user jobs response:', response);
            if(response.data && response.data.userJobs && response.data.userJobs.length > 0){
                setUserJobs(response.data.userJobs);
                props.setActiveJobID(response.data.userJobs[0].id);
            }
            setLoading(false);
        })
        .catch(err => {
            // console.log('user Jobs err:', err);
            err.response ? console.log("JobComponent get user jobs error:", err.response.data.message) : console.log("JobComponent get user jobs error:", err)
            setLoading(false);
            if (err.response.data.forceLogout){ forceLogOut(history, err); }
        });
    }, [])

    return (
        <div className="card-container">
            {userJobs && userJobs.length > 0 && userJobs.map((job, index) => {
                // console.log('jobComponent job:', job);
                // console.log('currentComponent:', props.currentComponent);
                if (job.status === props.currentComponent){
                    return <JobCard key={index} index={index} item={job} currentComponent={props.currentComponent} 
                    activeJobID={props.activeJobID} setActiveJobID={props.setActiveJobID} setRecheckGoals={props.setRecheckGoals} />
                }
            })}
            {userJobs && userJobs.length ? null : loading ? <h1>Loading...</h1> : <p>No Jobs found</p>}
        </div>
    )
}