import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import DashboardNav from "./DashboardNav.js";
import SuggestedJobs from "./Jobs/SuggestedJobs.js";
import JobComponent from './Jobs/JobComponent.js';
import History from './Jobs/History.js';
import ViewJob from '../Global/ViewJob.js';


export default function Dashboard(props) {
    const [loading, setLoading] = useState(false);
    const [activeJobID, setActiveJobID] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [jobs, setJobs] = useState([]);
    const [jobStatusArray, setJobStatusArray] = useState([]);
    const [recheckGoals, setRecheckGoals] = useState('');

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <DashboardNav setJobs={setJobs} setJobStatusArray={setJobStatusArray} setActiveJobID={setActiveJobID} setLoading={setLoading} recheckGoals={recheckGoals} setRecheckGoals={setRecheckGoals}/>
            {/* <SearchBar /> ?? */}
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-evenly', alignItems: 'flex-start', marginTop: '-136px'}}>
                <Route exact path='/Dashboard/' render={props => <SuggestedJobs {...props} activeJobID={activeJobID} setActiveJobID={setActiveJobID} 
                activeStatus={activeStatus} setActiveStatus={setActiveStatus} jobs={jobs} jobStatusArray={jobStatusArray} setRecheckGoals={setRecheckGoals} />} />
                <Route exact path='/Dashboard/History' render={props => <History {...props} activeJobID={activeJobID} setActiveJobID={setActiveJobID} setRecheckGoals={setRecheckGoals} />} />
                <Route exact path='/Dashboard/Jobs/Viewed' render={props => <JobComponent {...props} currentComponent={'Viewed'} activeJobID={activeJobID} setActiveJobID={setActiveJobID}setRecheckGoals={setRecheckGoals} />}/>
                <Route exact path='/Dashboard/Jobs/Saved' render={props => <JobComponent {...props} currentComponent={'Saved'} activeJobID={activeJobID} setActiveJobID={setActiveJobID}setRecheckGoals={setRecheckGoals} />}/>
                <Route exact path='/Dashboard/Jobs/Blocked' render={props => <JobComponent {...props} currentComponent={'Blocked'} activeJobID={activeJobID} setActiveJobID={setActiveJobID}setRecheckGoals={setRecheckGoals} />}/>
                <ViewJob {...props} id="dashViewJob" jobID={activeJobID} setActiveStatus={setActiveStatus} setRecheckGoals={setRecheckGoals} />
            </div>
        </div>
    )
}