import axiosWithAuth from '../../utils/axiosWithAuth.js';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const STORE_TOKEN = 'STORE_TOKEN';


export const logout = () => { return ({ type: LOGOUT, payload: null }); }
export const getCurrentUser = () => dispatch => {
    axiosWithAuth().get('/users/user')
    .then(res =>{
        dispatch({ type: SET_CURRENT_USER, payload: res.data })
    })
    .catch(err => {dispatch({ type: LOGIN_FAILED, payload: err }); console.log('GetCurrentUser CATCH ERROR: ', err.response.data.message) });
    return null;
}
export const updateUser = (userObj, setLoading) => dispatch => {
    axiosWithAuth().put(`/users/user`, userObj)
    .then(res =>{
        // console.log("update user", res);
        dispatch({ type: SET_CURRENT_USER, payload: res.data })
        // dispatch({ type: SET_OTHER_USER, payload: res.data })
    })
    .catch(err => {
        setLoading(false);
        console.log('updateUser CATCH ERROR: ', err.response.data.message);  
        alert(err.response.data.message); });
    return null;
}
export const updateProfilePicture = (formData, setPictureLoading) => dispatch => {
    // console.log('updateProfilePicture firing', formData);
    axiosWithAuth().put('/users/user/picture', formData)
    .then(res =>{
        // console.log('updateProfilePicture res: ', res);
        dispatch({ type: SET_CURRENT_USER, payload: res.data });
        setPictureLoading(false);
    })
    .catch(err => { console.log('updateProfilePicture CATCH ERROR: ', err.response.data.message) 
        alert(err.response.data.message); 
        setPictureLoading(false);} );
    return null;
}
export const deleteProfilePicture = (setPictureLoading) => dispatch => {
    // console.log('deleteProfilePicture firing');
    axiosWithAuth().delete('/users/user/picture')
    .then(res =>{
        // console.log('deleteProfilePicture res: ', res);
        dispatch({ type: SET_CURRENT_USER, payload: {profile_img: ''} });
        setPictureLoading(false);
    })
    .catch(err => { console.log('deleteProfilePicture CATCH ERROR: ', err.response.data.message) 
        alert(err.response.data.message); 
        setPictureLoading(false);});
    return null;
}
export const storeToken = (token) => {
    return { type: STORE_TOKEN, payload: token };
}
export const setLoginFailed = (err) => {
    return { type: LOGIN_FAILED, payload: err };
}