import React, { useState } from 'react';
import axiosWithAuth from '../../../../utils/axiosWithAuth.js';
import { convertToFormData } from '../../../../utils/AppUtils.js';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import FileUpload from "./FileUpload.js";


export default function EditForm(props) {
    const [loading, setLoading] = useState(false);
    const [report, setReport] = useState(
        props.report 
        ? {
            title: props.report.title,
            description: props.report.description,
            videoUrl: props.report.videoUrl ? props.report.videoUrl : '',
        }
        : {
            title: props.comment.title,
            description: props.comment.description,
            videoUrl: props.comment.videoUrl ? props.comment.videoUrl : '',
        }
    );
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');

    const handleChange = e => { setReport({ ...report, [e.target.name]: e.target.value }); }
    
    const handleCancel = () => {
        if (props.comment){ props.setEditingCommentID(''); }
        else{ props.setEditingReport(''); }
    }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        let editUrlPath = ''
        if (props.comment){ editUrlPath = `/forms/comment/${props.comment.id}`; }
        else{ editUrlPath = `/forms/report/${props.report.id}`;}
        axiosWithAuth().put(editUrlPath, convertToFormData({...report, images, video}))
        .then(response =>{
            setLoading(false);
            if (props.comment){
                props.setEditingCommentID('');
                props.setComments(response.data.comments); 
            }
            else{
                props.setEditingReport('');
                props.setReport(response.data.report); 
            }
        }).catch(err => {
            setLoading(false);
            console.log('EditForm handleSubmit error:', err.response.data.message);
            alert(`EditForm handleSubmit error: ${err.response.data.message}`);
        })
    }
    
    return (
        <div className="formContainer">
            <StyledLoader active={loading} spinner text='Loading...'>
                <h3>{props.comment ? 'EDIT COMMENT' : props.report.type === 'bug' ? 'EDIT BUG REPORT' : 'EDIT SUGGESTION'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="formInputs">
                        <input type="text" name="title" value={report.title} placeholder="Enter Title" onChange={handleChange} />
                        <input type="text" name="videoUrl" value={report.videoUrl} placeholder="Enter an external video url (Not required)" onChange={handleChange} />
                        <label>Description<br/>
                            <textarea name="description" value={report.description} onChange={handleChange} placeholder="Describe the problem"/>
                        </label>
                    </div>
                    <FileUpload images={images} setImages={setImages} video={video} setVideo={setVideo}/>
                    <div className="formSubmit">
                        <button onClick={handleSubmit}>SUBMIT</button>
                        <button onClick={handleCancel}>CANCEL</button>
                    </div>
                </form>
            </StyledLoader>
        </div>
    )
}

const StyledLoader = styled(LoadingOverlay)`
    width:100%;
    border-radius: 10px;
    z-index: 2;
`;