import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import axiosWithAuth from '../../../../utils/axiosWithAuth.js';
import { convertToFormData, getCurrentComponent } from '../../../../utils/AppUtils.js';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import FileUpload from "./FileUpload.js";


export default function SubmitForm() {
    const [loading, setLoading] = useState(false);
    const [currentComponent] = useState(getCurrentComponent(useLocation().pathname.toLocaleLowerCase()));
    const [report, setReport] = useState({
        title: '',
        description: '',
        images: [],
        videoUrl: '',
        type: currentComponent === 'bugs' ? 'bug' : 'suggestion'
    });
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState('');
    let history = useHistory();

    const handleChange = e => { setReport({ ...report, [e.target.name]: e.target.value }); }

    const handleSubmit = e => {
        e.preventDefault();
        setLoading(true);
        axiosWithAuth().post(`/forms/report`, convertToFormData({...report, images, video}))
        .then(response =>{
            setLoading(false);
            history.push(`/bugs/${response.data.reportId}`);
        }).catch(err => {
            setLoading(false);
            console.log('SubmitBugReport handleSubmit error:', err.response.data.message);
            alert(`SubmitBugReport handleSubmit error: ${err.response.data.message}`);
        })
    }

    return (
        <div className="formContainer">
            <StyledLoader active={loading} spinner text='Loading...'>
                <h3>SUBMIT {currentComponent === 'bugs' ? 'BUG REPORT' : 'SUGGESTION'}</h3>
                <form onSubmit={handleSubmit}>
                    <div className="formInputs">
                        <input type="text" name="title" value={report.title} placeholder="Enter Title" onChange={handleChange} />
                        <input type="text" name="videoUrl" value={report.videoUrl} placeholder="Enter an external video url (Not required)" onChange={handleChange} />
                        <label>Description<br/>
                            <textarea name="description" value={report.description} onChange={handleChange} placeholder="Describe the problem"/>
                        </label>
                    </div>
                    <FileUpload images={images} setImages={setImages} video={video} setVideo={setVideo}/>
                    <div className="formSubmit">
                        <button onClick={handleSubmit}>SUBMIT</button>
                    </div>
                </form>
            </StyledLoader>
        </div>
    )
}

const StyledLoader = styled(LoadingOverlay)`
    width:100%;
    border-radius: 10px;
    z-index: 2;
`;