import React, { useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import { isValidPassword, validateInputs } from '../../utils/AppUtils.js';
import { signUp, federatedSignIn, getIdTokenFromStorage } from '../../utils/cognitoFunctions.js';
import { getCurrentUser, storeToken } from '../../redux-store/App/AppActions.js';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function Register(props) {
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [newUser, setNewUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        zip: '',
        password: '',
        confirmPassword: '',
        emailOptIn: true,
    })

    const handleChange = e => { setNewUser({ ...newUser, [e.target.name]: e.target.value }); }
    
    const handleSubmit = e => {
        e.preventDefault();
        if (validateInputs(newUser) && isValidPassword(newUser.password, newUser.confirmPassword)) {
            setLoading(true);
            signUp(newUser).then((res)=>{
                // console.log('Register signUp res:', res);
                // user must verify their email address before being allowed to log in
                // set emailSent true to display directions to check email and come back after verifying
                setEmailSent(true); 
                setLoading(false);
                //token will be issued once user email is verified and they log in with email/pass to cognito.
            }).catch(err => {
                console.log('Register signUp error:', err);
                setLoading(false);
                alert(`Register signUp error: ${err.message}`);
            })
        }
    }

    const handleFederatedSignIn = (name) => {
        federatedSignIn(name)
            .then(() => {
                getIdTokenFromStorage().then((token) => {
                    props.storeToken(token);
                    props.getCurrentUser();
                    props.setLoading(true);
                    // props.history.push('/Dashboard');
                }).catch((err) => { console.log('Register federatedSignIn error:', err); setLoading(false); props.setLoading(false); });
            }).catch((err) => {
                console.log('Register federatedSignIn error:', err);
                setLoading(false);
                alert(`Register federatedSignIn error: ${err.message}`);
            });
    }

    return (
        <div className="registerParent">
            <StyledLoader className='register-container' active={loading} spinner text='Loading...' styles={loaderStyles}>
                <h3>{!emailSent ? 'REGISTER' : 'Welcome!'}</h3>
                { !emailSent ? <>
                    <form onSubmit={handleSubmit}>
                        <div className="form-inputs-register">
                            <div className="register-names">
                                <input type="text" name="first_name" value={newUser.first_name} placeholder="First Name" onChange={handleChange} />
                                <input type="text" name="last_name" value={newUser.last_name} placeholder="Last Name" onChange={handleChange} />
                            </div>
                            <input type="email" name="email" value={newUser.email} placeholder="Email" onChange={handleChange} />
                            <input type="tel" name="phone" value={newUser.phone} placeholder="Phone Number xxx-xxx-xxxx (Optional)" onChange={handleChange}/>
                            <input type="text" name="zip" value={newUser.zip} placeholder="Zip Code (xxxxx)" onChange={handleChange} />
                            <input type="password" name="password" value={newUser.password} placeholder="Password" onChange={handleChange} />
                            <input type="password" name="confirmPassword" value={newUser.confirmPassword} placeholder="Confirm Password" onChange={handleChange} />
                            <div className="emailOptIn">
                                <input type="checkbox" name="emailOptIn" value={newUser.emailOptIn} checked={newUser.emailOptIn} onChange={()=>{setNewUser({...newUser, emailOptIn: !newUser.emailOptIn})}}/>
                                <p>Can we send you emails?</p><FontAwesomeIcon style={{color: '#0077B5'}} icon={faInfoCircle} title="This is for announcements/marketing. You will always receive verification and password reset emails."/>
                            </div>
                        </div>
                    </form>
                    <button onClick={handleSubmit}>REGISTER</button>
                    <div className="google-registerDiv">
                        <button id="google-login" onClick={() => { handleFederatedSignIn("Google") }}>Sign in with Google</button><br />
                    </div>
                </>
                : 
                    <div className="registerEmailSent">
                        <h2>Please verify your email and come back to log in.</h2>
                        <Link to='/Login'><button>Login</button></Link>
                    </div>
                }
            </StyledLoader>
        </div>
    )
}
const mapStateToProps = state => {
    // console.log('mapstatetoprops: ', state);
    return { }
}

export default connect(mapStateToProps, { getCurrentUser, storeToken })(Register)

const StyledLoader = styled(LoadingOverlay)`width: 100%;`;

const loaderStyles = {
    overlay: (base) => ({
        ...base,
        borderRadius: '10px',
        background: '#ffffff',
        color: '#1351a8',
        fontWeight: 'bold'
    }),
    spinner: (base) => ({
        ...base,
        width: '10rem',
        '& svg circle': {
            stroke: '#1351a8'
        }
    })
}