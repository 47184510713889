import React from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { logOut } from '../../utils/AppUtils.js'
import Contact from './Contact.js';


export default function Header(props) {
    let history = useHistory();
    const location = useLocation();
    const path = location.pathname.toLocaleLowerCase();
    // console.log('location.pathname:', location.pathname, 'path:', path, 'loggedIn:', props.loggedIn)

    const login = <Link to='/Login'> <h3>Login</h3></Link>;
    const logout = <button onClick={() => { logOut(history); }}>Sign Out</button>;
    // const profile = <Link to="/Profile"><h3>Profile</h3></Link>;
    const register = <Link to='/Register'> <h3>Register</h3></Link>;
    const bugs = <Link to='/Bugs'> <h3>Bugs</h3></Link>;
    const submitBug = <Link to='/Bugs/Submit'> <h3>Submit Bug</h3></Link>;
    const sugs = <Link to='/Suggestions'> <h3>Suggestions</h3></Link>;
    const submitSug = <Link to='/Suggestions/Submit'> <h3>Submit Suggestion</h3></Link>;

    return (
        // idea- switch to !== path model, so that rather than manually specifying what goes where disable based off route 
        //and the rest will be shown in order
        <header>
            {props.loggedIn
                ? <> <Link to='/Dashboard'><div className="headerLogo"><h1>QUACKHIRE</h1><h4>Alpha</h4></div></Link>
                    <nav>
                        {path !== '/bugs' && path !== '/bugs/' && <>{bugs}</>}
                        {!path.includes('/bugs/submit') && path.includes('/bugs') && <>{submitBug}</>}
                        {path !== '/suggestions' && path !== '/suggestions/' && <>{sugs}</>}
                        {!path.includes('/suggestions/submit') && path.includes('/suggestions') && <>{submitSug}</>}
                        {/* {!path.includes('/profile') && <>{profile}</>} */}
                        <Contact/>
                        {logout}
                    </nav>
                </>
                : <> <Link to='/'><div className="headerLogo"><h1>QUACKHIRE</h1><h4>Alpha</h4></div></Link>
                    <nav>
                        {!props.loading && !path.includes('/dashboard') && <>
                            {!path.includes('/login') && <>{login}</>}
                            {!path.includes('/register') && <>{register}</>}
                        </> }
                        <Contact/>
                    </nav>
                </>
            }
        </header>
    );
}