import React from 'react';
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faDiscord, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Contact = () => {

    return (
        <div className="contact">
            <a href="mailto:quackhire@gmail.com" title='Email us'><FontAwesomeIcon className="contactIcon1" icon={faEnvelope} /></a>
            <a href="https://discord.gg/fENHZGc" title="Connect with us on Discord" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="contactIcon2" icon={faDiscord} /></a>
            {/* <FontAwesomeIcon style={{fontSize: '30px', color: '#0077B5'}} icon={faLinkedin} /> */}
        </div>
        // typeForm stuff?
        // <>
        //     {/* <div class="cui-embed" data-cui-uid="RTBQby0D" data-cui-mode="pill" data-cui-pill-button-color="#0000FF"></div><script src="https://public-assets.typeform.com/confab/embed.js" async></script> */}
        // </>
    )
}

export default Contact