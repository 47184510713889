import Amplify, { Auth, Hub } from "aws-amplify";
import getConfig from "./cognitoConfig.js";
const config = getConfig();
// import {CustomChromeStorage} from '../utils/customChromeStorage'

Amplify.configure({
    Auth: {
        userPoolId: config.userPool,
        userPoolWebClientId: config.clientId,
        region: config.region,
        oauth: {
            domain: config.userPoolUri,
            scope: config.tokenScopes,
            redirectSignIn: config.callbackUri,
            redirectSignOut: config.signoutUri,
            responseType: "code",
        },
        // storage: CustomChromeStorage
    },
});

async function signUp(newUser) {
    return await Auth.signUp({
        username: newUser.email,
        password: newUser.password,
        attributes: {
            'custom:first_name': newUser.first_name,
            'custom:last_name': newUser.last_name,
            'custom:phone_number': newUser.phone,
            'custom:zip_code': newUser.zip,
            'custom:emailOptIn': String(newUser.emailOptIn),
        }
    })
}

async function signIn(email, password) {
    return await Auth.signIn(email, password);
}

async function confirmSignUp(email, code) {
    return await Auth.confirmSignUp(email, code);
}

async function resendConfirmationCode(username) {
    return await Auth.resendSignUp(username);
}

// pass in true to sign out from all devices
async function signOut(global = false) {
    return await Auth.signOut({ global });
}

async function federatedSignIn(provider) {
    return await Auth.federatedSignIn({ provider });
}

async function forgotPassword(email) {
    return await Auth.forgotPassword(email);
}

async function forgotPasswordSubmit(email, code, newPassword) {
    try {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
        return "Password was changed successfully.";
    } catch (err) {
        throw err;
    }
}

async function changePassword(oldPassword, newPassword) {
    try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        return "Password was changed successfully.";
    } catch (err) {
        throw err;
    }
}

function getIdTokenFromStorage() {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then((data) => {
                const idToken = data.getIdToken();
                resolve(idToken.jwtToken);
            })
            .catch(() => {
                reject(Error("Not signed in."));
            });
    });
}

function getDecodedToken() {
    return new Promise((resolve, reject) => {
        Auth.currentSession()
            .then((data) => {
                const idToken = data.getIdToken();
                const user = idToken.payload;
                resolve(user);
            })
            .catch(() => {
                reject(Error("Not signed in."));
            });
    });
}

function cognitoSetup() {
    Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
            case "signUp":
                // console.log("User registered");
                break;
            case "cognitoHostedUI":
            case "signIn":
                break;
            case "signOut":
                // setCurrentUser(null);
                break;
            case "signIn_failure":
            case "cognitoHostedUI_failure":
                console.log("Sign in failure", data);
                break;
            default:
        }
    });
}

export { signUp, signIn, confirmSignUp, resendConfirmationCode, signOut, federatedSignIn, 
    forgotPassword, forgotPasswordSubmit, getDecodedToken, changePassword, getIdTokenFromStorage, cognitoSetup
};