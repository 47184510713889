import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axiosWithAuth from '../../../../utils/axiosWithAuth.js';


export default function ViewAllForms() {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [endpoint, setEndpoint] = useState('');
    let history = useHistory();
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    useEffect(() => {
        if (path.includes('bugs')){ setEndpoint('bugs'); }
        else if (path.includes('suggestions')){ setEndpoint('suggestions'); }
    }, [path])

    useEffect(() => {
        setLoading(true);
        if (endpoint){
            axiosWithAuth().get(`/forms/${endpoint}`)
            .then(response => {
                // console.log('get report response: ', response);
                setReports(response.data.reportList);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                console.log("viewAllForms get report error:", err);
                console.log("viewAllForms get report error:", err.response.data.message);
            });
        }
    },[endpoint]);

    return (
        <div className='viewAllForms'>
            {reports && reports.length 
                ? reports.map((report)=>{return reportCard(report, history, endpoint);})
                : loading ? <h1>Loading...</h1> : <h2>No {endpoint} found.</h2>
            }
        </div>
    )
}

const reportCard = (report, history, endpoint) => {
    return (
        <div className="formCard">
            <div style={{width: '80%', margin: '0 auto', cursor: 'pointer'}} onClick={()=>{history.push(`/${endpoint}/${report.id}`);}}>
                <div>
                    <h3>{report.title}</h3>
                </div>
                <div>
                    <h3>Description: </h3>
                    <p>{report.description}</p>
                </div>
            </div>
        </div>
    )
}