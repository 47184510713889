import React from 'react'
import { useLocation } from 'react-router-dom'

export default function Background(props) {
    const location = useLocation();
    const path = location.pathname.toLowerCase();

    return (
        <div className="background">
            {(path.includes('/login') || path.includes('/bugs')) && <>{login}</>}
            {(path.includes('/register') || path.includes('/suggestions') || path.includes('/viewjob')) && <>{register}</>}
        </div>
    )
}

const login = 
    <div className="container">
        <div className="redCircle loginCircle"></div>
        <div className="yellowRing loginRing"></div>
    </div>
;

const register = 
    <div className="container">
        <div className="redRing registerRing"></div>
        <div className="yellowCircle registerCircle"></div>
    </div>
;